<template>
  <Wrapper :notice="$t('auth.welcomeLogIn')">
    <div>
      <Account @callback="accountCallack"></Account>
    </div>
    <div class="item-card margin-top16">
      <van-row type="flex" justify="space-between">
        <van-col class="font14">{{ $t('auth.password') }}</van-col>
      </van-row>
      <div class="ipt-box margin-top8 df-styles">
        <van-field
          :type="fieldType"
          v-model="auth.password"
        >
          <div slot="right-icon" class="icon-box ub ub-cen" @click="changeFieldType">
            <img v-show="fieldType === 'password'"
              src="@/assets/images/system/close-eye.svg"
              alt="back"
              title="left-arrow"
            />
            <img v-show="fieldType === 'text'"
              src="@/assets/images/system/eye-o.svg"
              alt="back"
              title="left-arrow"
            />
          </div>
        </van-field>
      </div>
    </div>
    <div class="item-card margin-top8 font14 margin-bottom24 text-r">
      <span @click="goResetPassWord">{{ $t('auth.forgot') }}</span>
    </div>
    <div class="btn-box">
      <!-- :disabled="loginDisabled" -->
      <van-button @click="checkAuth" v-debounce :disabled="loginDisabled" class="btn-bg-color" type="info">{{$t('auth.login')}}</van-button>
    </div>
    <div class="other-box ub margin-top24 font14">
      <div @click="() => goSignUp('otp')" class="OPTlogin btn-text-color">{{ $t('auth.OPTlogin') }}</div>
      <div class="margin-right16 margin-left16 divider">|</div>
      <div @click="() => goSignUp('signUp')" class="signup btn-text-color">{{ $t('auth.signup') }}</div>
    </div>
  </Wrapper>
</template>

<script>
import { loginPwd } from '@/api/auth'
import Wrapper from '@/components/auth/wrapper/index.vue'
import Account from '@/components/auth/account/index.vue'
import Vue from 'vue'
import 'vant/lib/index.css'
import 'lib-flexible/flexible'
import { Row, Col } from 'vant'
import { mapActions } from 'vuex'

Vue.use(Row)
Vue.use(Col)

export default {
  components: { Account, Wrapper },
  computed: {
    loginDisabled  () {
      const getStatusObj = {
        phone: () => {
          return !(this.auth.phone && this.auth.password)
        },
        email: () => {
          return !(this.auth.email && this.auth.password)
        }
      }
      const status = getStatusObj[this.accountStatus]()
      return status
    }
  },
  data () {
    return {
      fieldType: 'password',
      enterCodeShow: false,
      accountStatus: 'email',
      countryObj: {},
      auth: {
        phone: '',
        email: '',
        password: '',
        mobile_code: ''
      },
      popupShow: false
    }
  },
  created () {
    this.LogOut()
  },
  methods: {
    goHome () {
      this.$router.go(-1)
    },
    loginPwdFn () {
      const mobile = this.auth.phone
      const mobileCode = this.auth.mobile_code
      const password = this.auth.password
      const email = this.auth.email
      return loginPwd({
        mobile: mobile,
        mobile_code: mobileCode,
        password: password,
        email: email
      }).then((res) => {
        const resData = res.data
        console.log(resData, '=====')
        try {
          this.$store.dispatch('updateToken', resData.token)
          this.$toast.clear()
          this.goHome()
        } catch (error) {
          console.log(error)
        }
      })
    },
    changeFieldType () {
      const typeObj = {
        password: 'text',
        text: 'password'
      }
      this.fieldType = typeObj[this.fieldType]
    },
    changeCodeShow () {
      this.enterCodeShow = !this.enterCodeShow
    },
    accountCallack (value) {
      console.log(value)
      this.accountStatus = value.accountStatus
      this.auth.phone = value.phone
      this.auth.email = value.email
      this.auth.mobile_code = value.mobile_code
    },
    checkAuth () {
      this.loginPwdFn()
    },
    showResetPassword () {
      this.popupShow = true
    },
    closeResetPassword () {
      this.popupShow = false
    },
    goSignUp (type) {
      this.$router.push(`/register/signUp/${type}`)
    },
    goVerification () {
      this.$router.push('/register/verification/login')
    },
    goResetPassWord () {
      this.$router.push('/register/resetPassWord')
    },
    ...mapActions(['LogOut'])
  }
}
</script>

<style lang="less">
.login-wrap{
  height: 100vh;
  box-sizing: border-box;
  // background-color: var(--background-color-4);
  background-color: #000;
  color: var(--main-text-1);
}
.login-main{
  height: 100%;
  background-color: var(--background-color-4);
}
.divider {
  color: var(--divider-color);
}
.logo-box{
  border-radius: 6px;
  width: 80px;
  height: 32px;
  background-color: var(--bg-2);
}
.icon-cross-box{
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: var(--bg-2);
}
.item-card{
  color: var(--main-text-2);
  .ipt-box{
    .van-cell{
      // background-color: var(--background-color-2);
      // color:  var(--main-text-1);
      .van-cell__value--alone input {
        color:  var(--main-text-1);
      }
    }
  }
  .phone-ipt{
    background-color: var(--background-color-2);
  }
}
.btn-box{
  box-sizing: border-box;
  width: 100%;
  .van-button{
    width: 100%;
    margin-left: 0%;
    border-radius: 16px;
    background-image: linear-gradient(to right, #5B21CB,#FA9BC3);
  }
  .van-button--info {
    border: none;
  }
}

</style>
